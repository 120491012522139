import React, { createContext, useState, useContext, useEffect, useCallback } from 'react'
import Cookies from 'js-cookie'
import { useRouter } from 'next/router'
import { AdminUserProps } from '../Props/AdminUserProps'
import adminApi from '@/services/adminApi'
import * as Sentry from '@sentry/nextjs'

interface LoginResponseProps {
  token: string
  expires_at: string
  error?: string
}

export type AdminAuthContextType = {
  isAuthenticated: boolean
  setIsAuthenticated: React.Dispatch<React.SetStateAction<boolean>>
  user: AdminUserProps
  login(email: string, password: string): Promise<LoginResponseProps>
  logout(): void
  loading: boolean
}

export const AdminAuthContext = createContext<AdminAuthContextType>({} as AdminAuthContextType)

export const AdminAuthProvider = ({ children }) => {
  const router = useRouter()
  const [user, setUser] = useState<AdminUserProps>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(
    typeof Cookies.get('token') == 'string'
  )

  const login = async (email: string, password: string): Promise<LoginResponseProps> => {
    try {
      const { data } = await adminApi.post<LoginResponseProps>('retailers/login', {
        email,
        password,
      })
      adminApi.defaults.headers.Authorization = `Bearer ${data.token}`
      Cookies.set('token', data.token, { expires: new Date(data.expires_at) })
      const { data: user } = await adminApi.get('users/me')
      setUser(user)
      Cookies.set('user', JSON.stringify(user), { expires: new Date(data.expires_at) })
      return data
    } catch (error) {
      Sentry.captureException(error)
      return { token: '', expires_at: '', error: 'Invalid user credentials' }
    }
  }

  const logout = useCallback(() => {
    Cookies.remove('token')
    setUser(null)
    setIsAuthenticated(false)
    delete adminApi.defaults.headers.Authorization
    router.replace('/')
  }, [router])

  useEffect(() => {
    const getUser = async () => {
      try {
        const { data: user } = await adminApi.get<AdminUserProps>('users/me')
        setUser(user)
        Cookies.set('user', JSON.stringify(user))
        setLoading(false)
      } catch (error) {
        Sentry.captureException(error)
        logout()
      }
    }
    const token = Cookies.get('token')
    const userCookie = Cookies.get('user')
    if (token) {
      adminApi.defaults.headers.Authorization = `Bearer ${token}`
      setIsAuthenticated(true)
      if (!userCookie) {
        getUser()
      } else {
        getUser()
        setUser(JSON.parse(userCookie))
        setLoading(false)
      }
    } else setLoading(false)
  }, [logout])
  return (
    <AdminAuthContext.Provider
      value={{ isAuthenticated, setIsAuthenticated, user, login, loading, logout }}
    >
      {loading ? '' : children}
    </AdminAuthContext.Provider>
  )
}

export const useAdminAuth = () => useContext(AdminAuthContext)
