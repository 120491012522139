import { CartProvider } from '@/contexts/cart'
import { AdminAuthProvider } from '../contexts/adminAuth'
import GlobalStyle from '../styles/GlobalStyle'
import { ConfigProvider } from 'antd'
import ptPT from 'antd/lib/locale/pt_PT'
import { Provider } from 'next-auth/client'

function MyApp({ Component, pageProps }) {
  return (
    <ConfigProvider locale={ptPT}>
      <AdminAuthProvider>
        <GlobalStyle />
        <Component {...pageProps} />
      </AdminAuthProvider>
    </ConfigProvider>
  )
}

export default MyApp
